<template>
<div v-if="showDialog" class="overlay" @click.stop="cancel"> <!-- 蒙版层 -->
	<div class="dialog" @click.stop>
		<div class="dialog_title">
			<div>修改搜索引擎</div>
		</div>
		<div class="dialog-content">
			<div>
				<label>网    址：</label>
				<input @input="checkValidURL" type="text" v-model="url" placeholder="必填，以 http://或https://开头的网址" />
			</div>
			<p v-if="!isValidURL">必填，以http://或https://开头，且不能超过 {{ maxLength }} 个字符</p >
		</div>
		<div class="dialog-footer">
			<button @click="cancel">取消</button>
			<button @click="putSE" :disabled="!isValidURL" >修改</button>
		</div>
	</div>
</div>
</template>

<script>
import { api,PUT,USER } from '../api';
import { eventBus } from '../event-bus.js';

export default {
props: {
},

data() {
	return {
	showDialog: false,
	url: "",
	maxLength:200,
	isValidURL:false,
	};
},

watch:{
},

mounted() {
},

methods: {
	
	checkValidURL() {
		const urlRegex = /^(https?:\/\/)/; // 正则表达式匹配以 http:// 或 https:// 开头的字符串
		this.isValidURL = (this.url.length <= this.maxLength) && urlRegex.test(this.url);
	},
	
	show() {
		this.showDialog = true;
	},
	
	cancel() {
		this.showDialog = false;
	},
	
	putSE() {
		this.cancel(); 
		const dataDict = {};
		dataDict["url"]=this.url;
		api(PUT,USER,dataDict).then(data => {
			if (data && data.code && data.code == 200){
				localStorage.setItem("search_engine",this.url);
				eventBus.$emit('refresh-SEMenuCom');// 触发事件
			}
		});
	},
	
},
};
</script>

<style scoped>

.dialog_title{
margin-bottom: 15px;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
z-index: 999; /* 确保蒙版在底部内容之上，但在对话框之下 */
display: flex;
align-items: center;
justify-content: center;
}

.dialog {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
z-index: 1000;
}

.dialog-content {
margin-bottom: 10px;
display: flex;
flex-direction: column;
align-items: flex-start; /* 确保子元素顶部对齐 */
}

.dialog-content input {
width: 400px;
font-size: 14px;
outline: none 0;
border: 1px solid #999;
}

.dialog-content input:focus {
border: 1px solid #2BA245;
}

.dialog-content textarea {
width: 400px;
height: 100px;
resize:none;
font-size: 14px;
}

.dialog-content p {
color:red;
font-size: 14px;
}

.dialog-footer {
display: flex;
justify-content: space-evenly;
margin-top: 20px;
}
</style>
