<template>
	<table v-if="currentItems.length > 0" class="table">
		<thead>
			<tr>
				<th>网址</th>
				<th>备注</th>
				<th>操作</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(item, index) in currentItems" :key="index">
				<td><a class="clickable-tag" @click="openNewTab(item.url)">{{ item.url }}</a></td>
				<td>{{ item.remark }}</td>
				<td>
					<span class="clickable-tag" @click="putURL(item.id,item.url,item.remark)">修改</span>
					<span class="clickable-tag left" @click="deleteURL(item.id,item.url,item.remark)">删除</span>
				</td>
			</tr>
		</tbody>
	</table>
	<div v-if="currentItems.length === 0 && searchQuery" class="blank-div">
		<span>没有匹配的数据。</span>
	</div>
	<div v-if="showDialog" class="overlay" @click.stop="cancel"> <!-- 蒙版层 -->
		<div class="dialog" @click.stop>
			<div class="dialog_title">
				<div>{{ title }}</div>
			</div>
			<div class="dialog-content">
				<div>
					<label>网    址：</label>
					<input :disabled="oper === 'delete'" @input="checkValidURL" type="text" v-model="url" placeholder="必填，以 http://或https://开头的网址" />
				</div>
				<p v-if="!isValidURL">必填，以http://或https://开头，且不能超过 {{ maxLength }} 个字符</p >
				<div v-if="isValidURL" class="blank-div-20"></div>
				<div>
					<label>备    注：</label>
					<textarea :disabled="oper === 'delete'" @input="checkValidDES" v-model="remark" placeholder="必填，该网址的备注" ></textarea>
				</div>
				<p v-if="!isValidDES">必填，备注不能超过 {{ maxLength }} 个字符</p >
			</div>
			<div class="dialog-footer">
				<button @click="cancel">取消</button>
				<button v-if="oper === 'delete'" @click="deleteApi" >删除</button>
				<button v-if="oper === 'put'" @click="putApi" :disabled="!(isValidURL && isValidDES)" >修改</button>
			</div>
		</div>
	</div>
</template>

<script>
import { api,POST,DELETE,PUT,URL } from '../api';
import { eventBus } from '../event-bus.js';

export default {
components: {
},
name: 'ListCom',
props: {
	currentItems: {
		type: Array,
		default:()=> []
	},
	searchQuery:{
		type: String,
		default: ""
	},
},

data() {
    return {
	    title:"",
	    oper:"",
	    id:"",
	    url:"",
	    remark:"",
	    showDialog: false,
	    maxLength:200,
	   isValidURL:false,
	   isValidDES:false,
    };
},

watch:{
	id(newVal){
		this.id = newVal;
	},
	
	url(newVal){
		this.url = newVal;
		this.checkValidURL();
	},
	
	remark(newVal){
		this.remark = newVal;
		this.checkValidDES();
	}
},

methods: {

	putURL(id,url,remark) {
		this.showDialog = true;
		this.title = "修改网址";
		this.oper = "put";
		this.id = id;
		this.url = url;
		this.remark = remark;
	},
	
	putApi() {
		this.showDialog = false;
		const dataDict = {};
		dataDict["id"]=this.id;
		dataDict["url"]=this.url;
		dataDict["remark"]=this.remark;
		api(PUT,URL,dataDict).then(data => {
			if (data && data.code && data.code == 200){
				eventBus.$emit('refresh-URLMenuCom');// 触发事件
			}
		});
	},
	
	deleteURL(id,url,remark) {
		this.showDialog = true;
		this.title = "删除网址";
		this.oper = "delete";
		this.id = id;
		this.url = url;
		this.remark = remark;
	},
	
	deleteApi() {
		this.showDialog = false;
		const dataDict = {};
		dataDict["id"]=this.id;
		api(DELETE,URL,dataDict).then(data => {
			if (data && data.code && data.code == 200){
				eventBus.$emit('refresh-URLMenuCom');// 触发事件
			}
		});
	},
	
	openNewTab(url) {
		window.open(url,"_blank");
	},
	
	checkValidURL() {
		const urlRegex = /^(https?:\/\/)/; // 正则表达式匹配以 http:// 或 https:// 开头的字符串
		this.isValidURL = (this.url.length <= this.maxLength) && urlRegex.test(this.url);
	},

	checkValidDES() {
		this.isValidDES = (this.remark.length <= this.maxLength) && (this.remark.length > 0 ) ;
	},
	
	cancel(){
		this.showDialog = false;
	},
	
},
};
</script>

<style scoped>

.left{
margin-left:10px;
}

.table {
width: 80%;
table-layout: fixed; 
border-collapse: collapse;
margin: 0px auto;
margin-bottom: 20px;
font-size: 16px;
}

.blank-div{
	width: 80%;
	margin: 20px auto;
	font-size: 14px;
}


.table th:nth-child(1),td:nth-child(1)  {
width: 40%; /* 第一列宽度为40% */
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.table th:nth-child(2),td:nth-child(2)  {
width: 40%; /* 第一列宽度为40% */
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.table th:nth-child(3),td:nth-child(3)  {
width: 20%; /* 第一列宽度为40% */
}

.table th,
.table td {
padding: 8px;
text-align: left;
border-bottom: 1px solid #ddd;
}

.clickable-tag {
color: #0d6efd;
}
.clickable-tag:hover {
cursor: pointer; /* 鼠标悬停时变为手指形状，表示可点击 */
text-decoration: underline;
}

.error {
color: red;
margin-top: 16px;
}


.dialog_title{
margin-bottom: 15px;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
z-index: 999; /* 确保蒙版在底部内容之上，但在对话框之下 */
display: flex;
align-items: center;
justify-content: center;
}

.dialog {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
z-index: 1000;
}

.dialog-content {
margin-bottom: 10px;
display: flex;
flex-direction: column;
align-items: flex-start; /* 确保子元素顶部对齐 */
}

.dialog-content input {
width: 400px;
font-size: 14px;
outline: none 0;
border: 1px solid #999;
}

.dialog-content input:focus {
border: 1px solid #2BA245;
}

.dialog-content textarea {
width: 400px;
height: 100px;
resize:none;
font-size: 14px;
outline: none 0;
border: 1px solid #999;
}

.dialog-content textarea:focus{
border: 1px solid #2BA245;
}

.dialog-content p {
color:red;
font-size: 14px;
}

.dialog-footer {
display: flex;
justify-content: space-evenly;
margin-top: 20px;
}

.blank-div-20{
	height: 20px;
}

</style>
