<template>
	<form @submit.prevent="handleSearch">
		<input ref="inputRef" class="input" type="text" v-model="searchQuery" placeholder=".查询全部 ｜ 多个关键字使用空格隔开" />
		<button class="button" type="submit"><div class="hollow_circle"></div></button>
	</form>
</template>

<script>

export default {
name: 'SubSearchCom',
data() {
    return {
    	searchQuery:"",
    };
},

mounted() {
	this.$refs.inputRef.focus();
},

methods: {
	handleSearch() {
		if(this.searchQuery.trim()){
			this.$emit('search', this.searchQuery.trim());
		}
	},
},
};
</script>

<style scoped>

.input {
font-size: 16px;
height: 32px;
border: 0;
outline: none 0;
width: 80%;
border: none;
border-bottom: 1px solid #ddd;
outline: none;
}

.input:focus {
/*border-bottom: 1px solid #0d6efd;*/
border-bottom: 1px solid #2BA245;
}

.button {
border: 0;
font-size: 16px;
background-color: transparent;
color: #333;
height: 32px;
position: absolute;
}

.button:hover {
cursor: pointer; 
}

.hollow_circle {
width: 30px;
height: 30px;
border: 1px solid #ddd; 
border-radius: 50%; /* 设置边框圆角为50%，形成圆形 */
background-color: transparent; /* 设置背景色为透明，这样就是空心的了 */
}

.hollow_circle:hover {
/*border: 1px solid #0d6efd;*/
}


</style>
