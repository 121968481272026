<template>
<div class="main-page">
	<div class="menu-area">
		<div class="menu-item trigger"  @click="homePage">首页</div>
		<div class="menu-item trigger" :class="{ active: activeMenu === 'MenuA' }" @click="setActiveMenu('MenuA')">管理网址</div>
		<div class="menu-item trigger" :class="{ active: activeMenu === 'MenuB' }" @click="setActiveMenu('MenuB')">管理搜索引擎</div>
		<div class="menu-item trigger" :class="{ active: activeMenu === 'MenuC' }" @click="setActiveMenu('MenuC')">使用说明</div>
	</div>
	<div class="content-area">
		<component :is="activeMenu"></component>
	</div>
</div>
</template>

<script>
import SEMenuCom from './SEMenuCom.vue';
import URLMenuCom from './URLMenuCom.vue';
import OperMenuCom from './OperMenuCom.vue';

export default {
components: {
	MenuA: URLMenuCom,
	MenuB: SEMenuCom,
	MenuC: OperMenuCom,
},

data() {
	return {
		activeMenu: 'MenuC' // 初始时设置为第三个菜单项
	};
},

methods: {

	setActiveMenu(menu) {
		this.activeMenu = menu; // 点击时更新 activeMenu 的值
	},
	
	
	homePage(){
		this.$router.push("/");
	},
}

};
</script>

<style scoped>

.trigger:hover {
/*color: #0d6efd;*/
cursor: pointer;
background: #54b96a;
color: #fff;
}

.menu-item {
margin: 0px auto;
font-size: 16px;
color: #4D4D4D;
padding: 8px;
}

.menu-item.active {
/*background-color: #f0f0f0;*/
background: #2BA245;
color: #fff;
}

.main-page {
display: flex;
}

.menu-area {
width: 20%;
}

.content-area {
width: 80%;
}
</style>
