<template>
	<div class="search">
		<div class="search_input">
			<input ref="inputRef" class="input" type="text"  @focus="inputFocus"  @blur="inputBlur"  v-model="searchQuery" @keyup.enter="handleSearch" placeholder="先搜索收藏(多个关键字使用空格隔开) ｜ 后搜索引擎" />
			<button class="button" type="submit" @click="handleSearch"><div class="hollow_circle"></div></button>
		</div>
		<div class="search_list">
			<ul v-if="isSelecting" >
				<li ref="listItemsRef" v-for="(option ,index) in options" :key="index"  @click="handleOptionSelected(option)">
					<div class="text-container">
						<div class="first-text">
							<a :href="option.url"></a>
							<span ref="urlRefs">{{ option.urlModify }}</span>
						</div>
						<div class="second-text">
							<span ref="descriptionRefs">{{ option.descriptionModify }}</span>
						</div>
						<div class="one-pixel-line"></div>
					</div>
				</li>
				<li v-if="hasMore" class="more-li">
					<span @click="loadDataMethod">加载更多</span>
				</li>
				<li v-if="!hasMore" class="no-li">
					<span>没有数据了。</span>
				</li>
			</ul>
		</div>
		<div v-if="isLogin && searchQuery && keywords.length>0 && options.length ===0" class="blank-div">
			<span>没有匹配的数据。</span>
		</div>
	</div>

    <!-- 右下角按钮，根据isScrollTopVisible的值决定是否显示 -->  
    <button @click="scrollToTop" v-show="isScrollTopVisible" class="scrollToTopButton">回到顶部</button> 

</template>

<script>
import { checkLocalLoginApi,api,GET,URL_S } from '../api';
import Mark from 'mark.js';
import store from '../store';
import { eventBus } from '../event-bus.js';

export default {

name: 'SearchCom',

data() {
    return {  
		//先搜索收藏(多个关键字使用空格隔开) ｜ 后搜索引擎
		isLogin:false,	//是否登陆
	    searchQuery:"",  //输入框的内容

	    isSelecting: false,  //是否显示列表
	    lastCursorPosition: 0,  //最后光标的位置（输入框的）
	    currentFocusIndex: -1, // 焦点的位置。-1表示焦点在输入框
	    options: [],// 存储从后端获取的数据
	    keywords: [], // 存储需要高亮的关键字
	    urlRefs: [], // 存储DOM引用的数组
	    descriptionRefs: [], // 存储DOM引用的数组

		page:1,     //当前页码
		pageSize:10,//每页的大小
		paramDict:{}, //发送请求的参数
		hasMore: true,//是否有更多的数据

		// 初始时假设没有滚动条  
		isScrollTopVisible: false,  
    };
},
    
watch: {
	// 监听 inputValue 的变化，可能需要显示下拉列表
	searchQuery(newVal,olVal) {
		// 与上次保存的值不同，并且新值不为空
		if (newVal.trim()!==olVal.trim() &&　newVal.trim() !== ''){
			let paramList = newVal.trim().replace(/ +/g,' ').split(' ');
			this.paramDict = {};
			this.hasMore = true;
			this.page = 1;
			this.paramDict["pc"]=paramList.length;
			this.paramDict["page"]=this.page;
			paramList.forEach((item, index) => {this.paramDict[`w${index + 1}`] = item;});
			this.getDataList(GET,URL_S,this.paramDict);
		}
		//新值为空
		if(newVal.trim() === ''){
			this.options = [];
			this.isSelecting = false;
		}
		
	}
},
	
	
mounted() {
	window.addEventListener('keydown', this.handleKeyDown);
	this.$refs.inputRef.focus();
	this.isLogin = checkLocalLoginApi();
	eventBus.$on('refresh-SearchCom', this.refresh);// 监听事件
	// 组件挂载后添加滚动事件监听器  
    window.addEventListener('scroll', this.handleScroll); 
},

beforeUnmount() {
	window.removeEventListener('keydown', this.handleKeyDown);
	eventBus.$off('refresh-SearchCom', this.refresh);// 组件卸载时移除事件监听器
	// 组件销毁前移除滚动事件监听器，防止内存泄漏  
    window.removeEventListener('scroll', this.handleScroll); 
},


methods: {
	scrollToTop() {  
		window.scrollTo({  top: 0,  behavior: 'smooth', });  
	},  
 
    handleScroll() {  
      // 假设当滚动条位置大于100px时显示按钮  
      this.isScrollTopVisible = window.scrollY > 100;  
    }, 

	refresh(){
		this.searchQuery = "";
		this.currentFocusIndex = -1;
		this.$refs.inputRef.focus();
		this.isLogin = checkLocalLoginApi();
	},
	
	highlightTexts() {
		// 清除之前的高亮（如果有的话）
		this.urlRefs.forEach(ref => {
			const instanceURL = new Mark(ref);
			if (instanceURL) {
			instanceURL.unmark();
			}
		});
		this.descriptionRefs.forEach(ref => {
			const instanceDES = new Mark(ref);
			if (instanceDES) {
			instanceDES.unmark();
			}
		});
		this.urlRefs=[]; // 存储DOM引用的数组
		this.descriptionRefs=[]; // 存储DOM引用的数组
		this.$nextTick(() => {
		this.options.forEach((option, index) => {
			const urlContext = this.$refs.urlRefs[index]; // 获取对应的DOM元素
			const descriptionContext = this.$refs.descriptionRefs[index]; // 获取对应的DOM元素
			this.urlRefs.push(urlContext); // 将引用添加到数组中以便稍后清除高亮
			this.descriptionRefs.push(descriptionContext); // 将引用添加到数组中以便稍后清除高亮
			// 使用新的搜索词数组创建新的实例
			const instanceURL = new Mark(urlContext);
			instanceURL.mark(this.keywords, {
			className:'mark',
			separateWordSearch: false, // 设置为 false 以便匹配整个短语（如果需要）
			});
			// 使用mark.js高亮文本
			const instanceDES = new Mark(descriptionContext);
			instanceDES.mark(this.keywords, {
			className:'mark',
			separateWordSearch: false, // 设置为 false 以便匹配整个短语（如果需要）
			});
		});
		});
	},

	getDataList(method, url, data = {}){
		if(this.isLogin){
			api(method, url, data).then(data => {
				this.options = [];
				this.isSelecting = false;
				if (data && data.code && data.code == 200){
					this.keywords = data.data.kw.split(' ');
					if(data.data.list.length>0){
						const result_options = [];
						data.data.list.forEach(item => {
							const result_dict={};
							result_dict['url']=item.ur;
							result_dict['urlModify']=item.ur;
							result_dict['descriptionModify']=item.re;
							result_options.push(result_dict);
						});
						this.options = result_options;
						this.isSelecting = true;
						this.highlightTexts();
					}
					if(data.data.list.length !== this.pageSize){
						this.hasMore = false;
					}
				}
			});
		}
	},

	loadData(method, url, data = {}){
		if(this.isLogin && this.hasMore){
			api(method, url, data).then(data => {
				if (data && data.code && data.code == 200){
					if(data.data.list.length>0){
						data.data.list.forEach(item => {
							const result_dict={};
							result_dict['url']=item.ur;
							result_dict['urlModify']=item.ur;
							result_dict['descriptionModify']=item.re;
							this.options.push(result_dict);
						});
						this.highlightTexts();
					}
					if(data.data.list.length !== this.pageSize){
						this.hasMore = false;
					}
				}
			});
		}
	},

	loadDataMethod(){
		this.page = this.page + 1;
		this.paramDict["page"]= this.page;
		this.loadData(GET,URL_S,this.paramDict);
	},
	
	
	inputFocus() {
		// 移除当前焦点的样式（如果有的话）
		if (this.currentFocusIndex !== -1) {
			this.$refs.listItemsRef[this.currentFocusIndex].classList.remove('focused');
		}
		this.currentFocusIndex = -1;
		setTimeout(() => {
			this.$refs.inputRef.selectionStart = this.lastCursorPosition;
			this.$refs.inputRef.selectionEnd = this.lastCursorPosition;
		}, 0);
	},

	inputBlur() {
		this.lastCursorPosition = this.$refs.inputRef.selectionStart;
		this.$refs.inputRef.blur();
	},

	handleKeyDown(event) {
		if (event.key === 'ArrowUp') {
			this.moveFocus('up');
		}
		if (event.key === 'ArrowDown') {
			this.moveFocus('down');
		}
		if (event.key === 'Enter') {
			if(this.isSelecting && this.currentFocusIndex !== -1){
				const aElement = this.$refs.listItemsRef[this.currentFocusIndex].querySelector('a');
				this.handleOptionSelectedCore(aElement.getAttribute('href'));
			}
		}
	},

	moveFocus(direction) {
		//console.log('3333333333333333:', this.currentFocusIndex);
		if (!this.isSelecting) return; // 如果下拉列表不可见，则不处理焦点移动
		let newFocusIndex = this.currentFocusIndex;
		if (direction === 'up') {
			newFocusIndex = Math.max(newFocusIndex - 1, -1); // -1 表示输入框
		} else if (direction === 'down') {
			newFocusIndex = Math.min(newFocusIndex + 1, this.options.length - 1);
		}
		// 移除当前焦点的样式（如果有的话）
		if (this.currentFocusIndex !== -1) {
			this.$refs.listItemsRef[this.currentFocusIndex].classList.remove('focused');
		}
		// 设置新焦点的样式
		if (newFocusIndex !== -1) {
			this.$refs.listItemsRef[newFocusIndex].classList.add('focused');
		}
		// 如果焦点在下拉列表项上，则移除输入框的焦点
		if (newFocusIndex !== -1) {
			this.lastCursorPosition = this.$refs.inputRef.selectionStart;
			this.$refs.inputRef.blur();
		} else {
			this.$refs.inputRef.focus();
		}
		if(direction === 'down' && this.hasMore && this.currentFocusIndex == (this.options.length-2)){
			//console.log("加载更多数据。",this.currentFocusIndex);
			this.loadDataMethod();
		}
		this.currentFocusIndex = newFocusIndex;
		},
	
	
	handleSearch() {
		if(this.searchQuery.trim()){
			let url = '';
			if(this.isLogin){
				url = localStorage.getItem("search_engine");
			}else{
				url = store.state.search_engine;
			}
			url = url+this.searchQuery;
			window.location.href=url;
		}
	},
	
	handleOptionSelected(option) {
		this.handleOptionSelectedCore(option.url);
	},
	
	handleOptionSelectedCore(url) {
		this.isSelecting = false; 
		window.location.href=url;
	},
},
};
</script>

<style scoped>

.one-pixel-line {
height: 1px; 
width: 100%;
background-color: #ddd; 
}

.focused {
/*background-color: #f2f2f2;
color: #2BA245;*/
background-color: #2BA245;
color: #fff;
}

.search {
text-align: left;
}

.search_list{
	font-size: 16px;
}

.search_list ul {
margin-left: 0px;
padding-left: 0px;
margin-top: 0px;
}

.search_list ul li {
list-style-type:none;
}

.search_list ul li:hover {
cursor: pointer; 
/*background-color: #f2f2f2;*/
background-color: #54b96a;
/*color: #0d6efd;*/
color: #fff;
}

.search_list ul li img{
width: 20px;
height: 20px;
}

.input {
font-size: 16px;
height: 32px;
width: 850px;
border: none;
border-bottom: 1px solid #999;
outline: none;
}

.input:focus {
/*border-bottom: 1px solid #0d6efd;*/
border-bottom: 1px solid #2BA245;
}

.button {
border: 0;
font-size: 16px;
background-color: transparent;
height: 32px;
position: absolute;
}

.button:hover {
cursor: pointer; 
}

.hollow_circle {
width: 34px;
height: 34px;
border: 1px solid #999; 
border-radius: 50%; /* 设置边框圆角为50%，形成圆形 */
background-color: transparent; /* 设置背景色为透明，这样就是空心的了 */
}

.hollow_circle:hover {
/*border: 1px solid #0d6efd;*/ 
}

.icon-image {
width: 20px;
height: 20px;
vertical-align: middle; /* 垂直居中，如果你需要的话 */
}

.text-container {
display: flex;
flex-direction: column;
}

.first-text {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
padding-top: 4px;
padding-left:8px;
padding-right:8px;
}

.second-text {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
padding-left:8px;
padding-right:8px;
padding-bottom: 4px;
padding-top: 2px;
}

.blank-div{
	text-align: center;
	font-size: 16px;
	margin-top: 30px;
}

.more-li {
	text-align: center;
	margin-top: 20px;
	font-size: 16px;
}

.search_list ul li.more-li:hover {
	cursor: pointer; 
	background-color: #fff;
	color: #54b96a;
}

.no-li{
	text-align: center;
	margin-top: 20px;
	font-size: 16px;
}

.search_list ul li.no-li:hover {
	cursor: default; 
	background-color: #fff;
	color: #2c3e50;
}

.scrollToTopButton {
	position: fixed;  
  	bottom: 100px;  
 	right: 50px; 
	border: none; /* 移除边框 */  
	background-color: #fff; /* 可选：设置背景颜色 */
	font-size: 16px;
}

.scrollToTopButton:hover {
	cursor: pointer; /* 鼠标悬停时显示手指形状，表明可点击 */ 
	color: #54b96a;
}

</style>
