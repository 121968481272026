<template>
     <div class="main">
	     <HeaderCom />
	     <AdminCom  />
	     <FooterCom />
     </div>
</template>

<script>
import HeaderCom from './HeaderCom.vue';
import AdminCom from './AdminCom.vue';
import FooterCom from './FooterCom.vue';
import { checkLocalLoginApi } from '../api';

export default {
components: {
	HeaderCom,
	AdminCom,
	FooterCom
},
    
data() {
    return {
    	isLogin:false,
    };
},

created() {
	this.isLogin = checkLocalLoginApi();
	this.checkLogin();
},

mounted(){
	
},

methods: {
	checkLogin(){
		if(!this.isLogin){
			this.$router.push("/");
		}
	},

}
};

</script>		

<style scoped>

.main{
width: 900px;
margin: 0 auto;
}

</style>
