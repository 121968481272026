<template>
	<div class="header">
		<div class="header_link">
			<div class="flex-container">
				<div class="flex-item trigger" v-show="isLogin"  @mouseenter="showDropdown"  @mouseleave="hideDropdown">更多</div>
				<div class="flex-item trigger" v-show="isLogin" @click="addURL">添加网址</div>
				<div class="flex-item trigger" v-show="!isLogin" @click="login">微信登陆</div>
				<div class="options move-below" v-show="showOptions"  >
					<div class="option trigger" @click="handleOptionClick('Option 1')" @mouseenter="preventHide" @mouseleave="resetHideTimer">管理</div>
					<div class="option trigger" @click="handleOptionClick('Option 2')" @mouseenter="preventHide" @mouseleave="resetHideTimer">退出</div>
				</div>
			</div>
		</div>
		<div class="header_height"></div>
	</div>
	<div v-if="showDialog" class="overlay" @click.stop="cancel"> <!-- 蒙版层 -->
		<div class="dialog" @click.stop>
			<div class="dialog_title">
				<div>添加网址</div>
			</div>
			<div class="dialog-content">
				<div>
					<label>网    址：</label>
					<input @input="checkValidURL" type="text" v-model="url" placeholder="必填，以 http://或https://开头的网址" />
				</div>
				<p v-if="!isValidURL">必填，以http://或https://开头，且不能超过 {{ maxLength }} 个字符</p >
				<div v-if="isValidURL" class="blank-div-20"></div>
				<div>
					<label>备    注：</label>
					<textarea @input="checkValidDES" v-model="remark" placeholder="必填，该网址的备注" ></textarea>
				</div>
				<p v-if="!isValidDES">必填，备注不能超过 {{ maxLength }} 个字符</p >
			</div>
			<div class="dialog-footer">
				<button @click="cancel">取消</button>
				<button @click="addApi" :disabled="!(isValidURL && isValidDES)" >添加</button>
			</div>
		</div>
	</div>
</template>

<script>
import { checkLocalLoginApi,api,POST,DELETE,PUT,URL } from '../api';
import { eventBus } from '../event-bus.js';

export default {
components: {
},

name: 'HeaderCom',

data() {
    return {
    	    isLogin:false,
    	    showOptions: false,
    	    hideTimeout: null,
	    token:"",
	    search_engine:"",
	    
	    showDialog: false,
	    url:"",
	    remark:"",
	    maxLength:200,
	    isValidURL:false,
	    isValidDES:false,
    };
},
    
watch: {
},
	

created() {
	this.isLogin = checkLocalLoginApi();
},
    
mounted() {
	eventBus.$on('refresh-HeaderCom', this.checkLogin);// 监听事件
	
},

beforeUnmount() {
	eventBus.$off('refresh-HeaderCom', this.checkLogin);// 组件卸载时移除事件监听器
},

methods: {
	checkLogin(){
		this.isLogin = checkLocalLoginApi();
	},
	
	preventHide() {
		// 当鼠标进入选项时，清除隐藏定时器
		if (this.hideTimeout) {
		clearTimeout(this.hideTimeout);
		this.hideTimeout = null;
	}
	},
	
	resetHideTimer() {
		// 当鼠标离开选项但还在容器内时，重新设置隐藏定时器
		// 这里可以根据需要调整逻辑，比如判断鼠标是否还在容器内等
		// 但为了简单起见，这里不添加额外判断
		this.hideDropdown();
	},
	
	showDropdown() {
		this.showOptions = true;
		// 清除之前可能存在的隐藏定时器
		if (this.hideTimeout) {
			clearTimeout(this.hideTimeout);
			this.hideTimeout = null;
		}
	},
	
	hideDropdown() {
		// 设置一个定时器来隐藏下拉选项，以便在鼠标离开时给用户一些时间来移动到选项上
		this.hideTimeout = setTimeout(() => {
		this.showOptions = false;
		}, 200); // 200毫秒的延迟
	},

	handleOptionClick(option) {
		// 根据选项执行不同的方法
		switch (option) {
			case 'Option 1':
				this.handleOption1();
				break;
			case 'Option 2':
				this.handleOption2();
				break;
			default:
				break;
		}
	},
	
	handleOption1() {
		this.hideDropdown(); // 点击后隐藏下拉选项
		this.$router.push("/mine");
	},
	
	handleOption2() {
		this.hideDropdown(); // 点击后隐藏下拉选项
		window.localStorage.clear();
		this.$router.push("/");
		this.isLogin = checkLocalLoginApi();
		eventBus.$emit('refresh-SearchCom');// 触发事件
	},

	login(){
		this.$router.push("/login");
	},
	
	checkValidURL() {
		const urlRegex = /^(https?:\/\/)/; // 正则表达式匹配以 http:// 或 https:// 开头的字符串
		this.isValidURL = (this.url.length <= this.maxLength) && urlRegex.test(this.url);
	},

	checkValidDES() {
		this.isValidDES = (this.remark.length <= this.maxLength) && (this.remark.length > 0 ) ;
	},
	
	cancel() {
		this.showDialog = false;
	},
	
	addURL(){
		this.showDialog = true;
	},
	
	addApi() {
		this.showDialog = false;
		const dataDict = {};
		dataDict["url"]=this.url;
		dataDict["remark"]=this.remark;
		api(POST,URL,dataDict).then(data => {
			if (data && data.code && data.code == 200){
				this.url="";
				this.remark="";
				this.checkValidURL();
				this.checkValidDES();
			}
		});
	},


},
};
</script>

<style scoped>

.header_height{
height:160px;
}

.header_link{
overflow:auto;
}

.trigger:hover {
/*color: #0d6efd;*/
color: #54b96a;
cursor: pointer;
}

.options {
position: absolute; /* 或其他定位方式 */
display: flex;
flex-direction: column;
background-color: #fff;
margin-top: 25px; 
}

.option {
margin-top: 10px; 
cursor: pointer;
font-size: 16px;
}

.flex-container {
display: flex;
flex-direction: row-reverse; /* 反向排列子元素 */
align-items: flex-start; /* 交叉轴上的对齐方式 */
}

.flex-item {
margin-left: 20px;
font-size: 16px;
}

.move-below {
margin-left: auto; /* 将div推到右边 */
}


.dialog_title{
margin-bottom: 15px;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
z-index: 999; /* 确保蒙版在底部内容之上，但在对话框之下 */
display: flex;
align-items: center;
justify-content: center;
}

.dialog {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
z-index: 1000;
}

.dialog-content {
margin-bottom: 10px;
display: flex;
flex-direction: column;
align-items: flex-start; /* 确保子元素顶部对齐 */
}

.dialog-content input {
width: 400px;
font-size: 14px;
outline: none 0;
border: 1px solid #999;
}

.dialog-content input:focus {
border: 1px solid #2BA245;
}

.dialog-content textarea {
width: 400px;
height: 100px;
resize:none;
font-size: 14px;
outline: none 0;
border: 1px solid #999;
}

.dialog-content textarea:focus{
border: 1px solid #2BA245;
}

.dialog-content p {
color:red;
font-size: 14px;
}

.dialog-footer {
display: flex;
justify-content: space-evenly;
margin-top: 20px;
}

.blank-div-20{
	height: 20px;
}

</style>
