<template>
	<SubSearchCom  @search="onSearch"/>
	<ListCom :currentItems="currentItems" :searchQuery="searchQuery" />
	<PaginationCom :currentPage="currentPage" :totalPages="totalPages" @page-changed="onPageChanged" />
</template>

<script>
import SubSearchCom from './SubSearchCom.vue';
import ListCom from './ListCom.vue';
import PaginationCom from './PaginationCom.vue';
import { api,GET,URL_SEARCH } from '../api';
import { eventBus } from '../event-bus.js';

export default {
components: {
	SubSearchCom,
	ListCom,
	PaginationCom
},
    
data() {
    return {
		searchQuery:"",
		currentPage: 1,
		totalPages: 0,
		totalCount:0,
		currentItems: []
    
    };
},
    
created() {
},
    
mounted(){
	// 监听事件
	eventBus.$on('refresh-URLMenuCom', this.fetchData);
},
    
beforeUnmount() {
	// 组件卸载时移除事件监听器
	eventBus.$off('refresh-URLMenuCom', this.fetchData);
},
    
methods: {

	fetchData(){
		let paramList = this.searchQuery.trim().replace(/ +/g,' ').split(' ');
		const paramDict = {};
		const paramCount = paramList.length;
		paramDict["page"]=this.currentPage;
		paramDict["param_count"]=paramCount;
		paramList.forEach((item, index) => {paramDict[`w${index + 1}`] = item;});
		api(GET,URL_SEARCH,paramDict).then(data => {
			if (data && data.code && data.code == 200){
				this.currentItems = data.data.list;
				this.totalPages = data.data.total_page;
				this.totalCount = data.data.total_count;
			}
		});
	
	},

	onSearch(keyword) {
	    this.searchQuery=keyword;
	    this.currentPage=1;
	    this.totalPages=0;
	    this.totalCount=0;
	    this.currentItems=[];
	    this.fetchData();
	},

	onPageChanged(newPage) {
		this.currentPage = newPage;
		this.fetchData();
	},

    
}
};

</script>		

<style scoped>

</style>
