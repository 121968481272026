<template>
	<div v-if="qrCodeUrl">
		<img :src="qrCodeUrl"  class="qrCodeUrl" alt="QR Code" />
		<p>使用微信扫码登陆，小程序码5分钟内有效。</p>
		<p>剩余时间：{{ remainingTime }} 秒</p>
		<p v-if="remainingTime === 0">小程序码无效，请手动刷新页面，重新扫码登陆。</p>
	</div>
</template>

<script>
import axios from 'axios';
import store from '../store';
import { checkLocalLoginApi,checkLoginApi,loginApi } from '../api';


export default {
data() {
	return {
		isLogin:false,
		sceneId: '',
		qrCodeUrl: '',
		remainingTime: 300,//剩余时间
		countdownTimer: null,
		operationTimer: null,
	};
},

mounted() {
	this.isLogin = checkLocalLoginApi();//判断本地是否登陆
	this.checkLocalLogin();//如果登陆状态，跳转到首页
	this.fetchQRCode();//获取小程序码
	this.startCountdown();//启动两个定时器
	//this.login('oGsUQ7VSK0NNn_RtRIJbXCw5ALi4','17224280341970000000');
},

beforeUnmount() {
	this.clearTimers();
},

methods: {
	checkLocalLogin(){
		if(this.isLogin){
			this.$router.push("/");
		}
	},

	checkLogin() {
	    	try {
			checkLoginApi(this.sceneId).then(data => {
			if (data && data.code && data.code == 200 && data.data.userid){
				this.clearTimers();
				this.login(data.data.userid,this.sceneId);
			}
			});
		} catch (error) {
		}
	},
	
	login(userId,sceneId) {
	    	try {
			loginApi(userId,sceneId).then(data => {
			if (data && data.code && data.code == 200){
				localStorage.setItem("login_time",Math.floor(new Date().getTime()));
				localStorage.setItem("token",data.data.token);
				localStorage.setItem("search_engine",data.data.search_engine);
				this.$router.push("/");
			}
			});
		} catch (error) {
		}
	},

	fetchQRCode() {
		// 获取当前时间的毫秒级时间戳，13位
		const timestamp = Date.now().toString();//1722428034197
		// 生成7位随机数字
		let randomPart = '';
		for (let i = 0; i < 7; i++) {
			randomPart += Math.floor(Math.random() * 10); // 生成0-9之间的随机整数
		}
		this.sceneId = timestamp + randomPart;
		axios.post(store.state.api_base_url+'/wechat/scene/login_qrcode', { sceneid: this.sceneId }).then(response => {
			this.qrCodeUrl = response.data.data.qrCodeUrl;
			//console.log(response.data.data.qrCodeUrl);
		}).catch(error => {
			//console.error('Error fetching QR code:', error);
		});
	},
	
	startCountdown() {
		this.countdownTimer = setTimeout(() => {
			this.clearTimers();
		}, 5 * 60 * 1000); // 5 分钟后清除定时器

		this.operationTimer = setInterval(() => {
			//console.log('执行操作');
			this.remainingTime -= 3;
			if(this.remainingTime <= 0){
				this.remainingTime = 0;
			}
			this.checkLogin();
		}, 3000); // 每3秒执行一次
	},
	
	clearTimers() {
		if (this.countdownTimer) {
			clearTimeout(this.countdownTimer);
			this.countdownTimer = null;
		}
		if (this.operationTimer) {
			this.remainingTime = 0;
			clearInterval(this.operationTimer);
			this.operationTimer = null;
		}
	},
}
};

</script>	

<style scoped> 

.qrCodeUrl{
	width: 200px;
	height: 200px;
	margin-top: 100px;
	margin-bottom: 30px;
}

p{
	font-size: 16px;
}


</style>

