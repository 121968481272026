<template>
     <div class="main">
	     <HeaderCom />
	     <SearchCom />
	     <FooterCom />
     </div>
</template>

<script>
import HeaderCom from './HeaderCom.vue';
import SearchCom from './SearchCom.vue';
import FooterCom from './FooterCom.vue';
import { refreshTokenApi,api,POST,REFRESH_TOKEN } from '../api';

export default {
components: {
	HeaderCom,
	SearchCom,
	FooterCom
},
    
data() {
    return {
    	
    };
},

created() {
	this.refreshToken();
},

mounted(){

},

methods: {

	refreshToken(){
		const loginTime = localStorage.getItem("login_time");
		const currentTimestamp = Math.floor(new Date().getTime());
		if(loginTime){
			if((Number(loginTime)+24*60*60*1000*20) < Number(currentTimestamp) && Number(currentTimestamp) < (Number(loginTime)+24*60*60*1000*30)){
				 api(POST,REFRESH_TOKEN).then(data => {
					if (data.code == 200){
						const token = data.data.token;
						localStorage.setItem("token",token);
						localStorage.setItem("login_time",currentTimestamp);
					}
					});
				
			}
		}
	}



}
};
    
</script>		

<style scoped>

.main{
width: 900px;
margin: 0 auto;
}

</style>
