<template>
	<div class="h3">首页的搜索框是统一入口，有两种搜索模式，分别是对外的搜索引擎和对内的搜索收藏。</div>
	<div>
		<div class="h4">一、对外的搜索引擎（如百度、谷歌）</div>
		<div class="h5">方法1：光标在输入框上，按回车键。</div>
		<div class="h5">方法2：鼠标点击圆圈按钮。</div>
	</div>
	<div>
		<div class="h4">二、对内的搜索收藏（已收藏的网址）</div>
		<div class="h5">方法1：选择和确定，对应到键盘的上下键和回车键。</div>
		<div class="h5">方法2：鼠标点击。</div>
	</div>
</template>

<script>


export default {
data() {
    return {
    };
},

created() {
},

mounted(){
},

beforeUnmount() {
},

methods: {
    
}
};

</script>		

<style scoped>

.h3 {
	font-size: 16px;
	text-align: left;
	margin-left: 70px;
}

.h4 {
	font-size: 16px;
	text-align: left;
	margin-left: 70px;
	margin-top: 14px;
}

.h5 {
	font-size: 14px;
	text-align: left;
	margin-left: 102px;
	margin-top: 5px;
}


</style>
