import { createApp } from 'vue'; // 引入Vue 3的createApp（尽管在这里并不直接使用它）

class EventBus {
constructor() {
this.callbacks = new Map();
}

$on(event, callback) {
if (!this.callbacks.has(event)) {
this.callbacks.set(event, []);
}
this.callbacks.get(event).push(callback);
}

$off(event, callback) {
const callbacks = this.callbacks.get(event);
if (callbacks) {
this.callbacks.set(event, callbacks.filter(cb => cb !== callback));
}
}

$emit(event) {
const callbacks = this.callbacks.get(event);
if (callbacks) {
callbacks.forEach(cb => cb());
}
}
}

// 导出 EventBus 的单例
export const eventBus = new EventBus();


