import { createRouter,createWebHistory} from 'vue-router';
import Index from './components/Index.vue';
import Mine from './components/Mine.vue';
import Login from './components/Login.vue';

//思维造物 ｜ 先搜索收藏，后搜索引擎
// 定义路由
const routes = [
{
path: '/',
name: 'Index',
component: Index,
meta: { title: '思维造物 ｜ 先搜索收藏，后搜索引擎' }
},
{
path: '/mine',
name: 'Mine',
component: Mine,
meta: { title: '思维造物 ｜ 先搜索收藏，后搜索引擎' }
},
{
path: '/login',
name: 'Login',
component: Login,
meta: { title: '思维造物 ｜ 微信登陆' }
},

{
path:'/:catchAll(.*)',
redirect:'/'

}

];

// 创建 router 实例，然后传 `routes` 配置
const router = createRouter({
history: createWebHistory(),
routes // 等价于 routes: routes
});

// 根据路由的 meta 字段来设置标题
router.beforeEach((to, from, next) => {
if (to.meta.title) {
document.title = to.meta.title;
}
next();
});

// 导出 router 实例，供其他模块使用
export default router;


