<template>
   	<form @submit.prevent="handlePut">
		<input class="input" :disabled="true" type="text" v-model="searchEngine" />
		<button class="button" type="submit"><div class="hollow-circle"></div></button>
	</form>
	<p class="font-size-16">1、以上是默认的搜索引擎。如需要修改搜索引擎，点击圆圈按钮进行修改。</p>
	<p class="font-size-16">2、以下是常用的搜索引擎，仅供参考。</p>
	<div class="table-container">
		<table>
			<tr>
				<th >搜索引擎</th>
				<th >网址</th>
				<th >备注</th>
			</tr>
			<tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
				<td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
			</tr>
		</table>
	</div>
	<SEDialogCom ref="dialog" />
</template>

<script>
import SEDialogCom from './SEDialogCom.vue';
import { eventBus } from '../event-bus.js';

export default {
components: {
	SEDialogCom,
},

data() {
    return {
    	searchEngine:"",
	tableData: [
	['谷歌', 'https://www.google.com/search?q=', '保证原有的网络可以访问谷歌'],	
	['百度', 'https://www.baidu.com/s?wd=', ''],
	['必应', 'https://cn.bing.com/search?q=', ''],
	['搜狗', 'https://www.sogou.com/web?query=', ''],
	['360', 'https://www.so.com/s?q=', ''],
	]
    };
},

created() {
	this.getSearchEngine();
},

mounted(){
	// 监听事件
	eventBus.$on('refresh-SEMenuCom', this.getSearchEngine);
},

beforeUnmount() {
	// 组件卸载时移除事件监听器
	eventBus.$off('refresh-SEMenuCom', this.getSearchEngine);
},

methods: {

	getSearchEngine(){
		this.searchEngine = localStorage.getItem("search_engine");
	},
	
	handlePut() {
		this.$refs.dialog.show();
	},

    
}
};

</script>		

<style scoped>

table {
border-collapse: collapse;
}

table, th, td {
border: 1px solid #ddd; 
padding: 10px;
}

.input {
font-size: 16px;
height: 32px;
border: 0;
outline: none 0;
width: 80%;
border: none;
border-bottom: 1px solid #ddd;
outline: none;
}

.button {
border: 0;
font-size: 16px;
background-color: transparent;
color: #333;
height: 32px;
position: absolute;
}

.button:hover {
cursor: pointer; 
}

.hollow-circle {
width: 30px;
height: 30px;
border: 1px solid #ddd; /* 设置边框颜色和粗细 */
border-radius: 50%; /* 设置边框圆角为50%，形成圆形 */
background-color: transparent; /* 设置背景色为透明，这样就是空心的了 */
}

.hollow-circle:hover {
/*border: 1px solid #0d6efd;*/
}

.table-container {
display: flex;
/* justify-content: center; 水平居中 */
font-size: 14px;
margin-left: 70px;
}

.table-container table tr th{
	font-weight: normal;
}

.font-size-16{
	font-size: 16px;
	text-align: left;
	margin-left: 70px;
}


</style>
