import axios from 'axios';
import store from './store';
import request from './request';


// 所有的请求方法  
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';

// 所有的请求URL   
export const URL_S = '/url/s';
export const URL_SEARCH = '/url/search';
export const URL = '/url';
export const USER = '/user';
export const REFRESH_TOKEN = '/refresh_token';


// 判断本地是否登陆  
export  function checkLocalLoginApi(){
	const loginTime = localStorage.getItem("login_time");
	const currentTimestamp = Math.floor(new Date().getTime());
	if(loginTime){
		if(Number(loginTime) < Number(currentTimestamp) && Number(currentTimestamp) < (Number(loginTime)+24*60*60*1000*30)){
			return true;
		}else{
			return false;
		}
	}else{
		return false;
	}
}


// 检查是否登陆
export async function checkLoginApi(sceneId) {
	const url = store.state.api_base_url+'/login?sceneid='+sceneId;
	const config = {
		method: 'get', 
		url: url,
		headers: {
		'Content-Type': 'application/json'
		},
	};
	try {
		const response = await axios(config);
		return response.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		//throw error;
	}
}
	

// 登陆
export async function loginApi(userId,sceneId) {
	const url = store.state.api_base_url+'/login';
	const config = {
		method: 'post', 
		url: url,
		headers: {
		'Content-Type': 'application/json'
		},
		data:{
		'userid':userId,
		'sceneid':sceneId,
		},
	};
	try {
		const response = await axios(config);
		return response.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}


// 通用的api接口（方法，url，数据），请求的头部添加token 
export async function api(method, url, data = {}) {
	try {
		const requestConfig = {
			url,
			method,
			data,
		};
		// 根据HTTP方法调整配置，比如GET请求不需要data
		if (method.toUpperCase() === 'GET') {
			requestConfig.params = data; // 假设data用于查询参数
		} else {
			requestConfig.data = data; // 对于POST、PUT、DELETE等，data保持原样
		}
		const response = await request(requestConfig);
		return response;
	} catch (error) {
		console.error(`${method.toUpperCase()}Api-error:`, error);
	}
}







