<template>
<div class="pagination">
	<button v-if="currentPage > 1" class="page-button" @click="goToPreviousPage">上一页</button>
	<span v-for="page in displayedPages" :key="page" class="page-number">
		<button :class="{ 'active': page === currentPage }" @click="goToPage(page)">{{ page }}</button>
	</span>
	<button v-if="currentPage < totalPages" class="page-button" @click="goToNextPage">下一页</button>
</div>
</template>

<script>
export default {
name: 'PaginationCom',
props: {
	currentPage: {
		type: Number,
		required: true
	},
	totalPages: {
		type: Number,
		required: true
	},
	pageSize: {
		type: Number,
		required: false,
		default: 30
	}
},
computed: {
	displayedPages() {
		const startPage = Math.max(1, this.currentPage - 2);
		const endPage = Math.min(this.totalPages, startPage + 5);
		return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
	}
},
methods: {
	goToPreviousPage() {
		if (this.currentPage > 1) {
			this.$emit('page-changed', this.currentPage - 1);
		}
	},
	
	goToPage(page) {
		if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
			this.$emit('page-changed', page);
		}
	},
	
	goToNextPage() {
		if (this.currentPage < this.totalPages) {
			this.$emit('page-changed', this.currentPage + 1);
		}
	}
}
};
</script>

<style scoped>
.pagination {
display: flex;
justify-content: center;
align-items: center;
}

.page-button {
margin: 0 5px;
}

.page-number .active {
font-weight: bold;
}
</style>
