<template>
<div class="footer">
	<div class="footer_height"></div>
	<div class="footer_text">
		<p><a href="https://beian.miit.gov.cn">粤ICP备2024260829号-1</a></p>
		<p>499638648@qq.com</p>
	</div>
</div>
</template>

<script>

export default {
name: 'Header',

data() {
    return {
   
    };
},
    
watch: {
	
},
    
mounted() {
	
},


methods: {

},
};
</script>

<style scoped>
.footer_height{
height: 350px;
}

.footer_text{
font-size: 12px;
color: #d8d8d8;;
}

.footer_text p a{
text-decoration: none;
color: #d8d8d8;
}

.footer_text p a:hover {
text-decoration: underline;
color: #54b96a;
}


</style>
