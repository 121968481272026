import axios from 'axios';
import store from './store';
import { eventBus } from './event-bus.js';


// 创建axios实例
const service = axios.create({
	baseURL: store.state.api_base_url, // 根据实际情况配置API的基础URL
	timeout: 60000, // 请求超时时间，60秒
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// 在这里添加token到请求头 
		const token = localStorage.getItem('token'); // 假设你将token存储在localStorage中  
		//const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ6.eyJzdWIiOiJhIn0.TTuehSdegk91s5ENW5PArpCP0-aplxS1PsZDb_PHouo";
		if (token) {
			config.headers.Authorization = `Bearer ${token}`; // 根据你的后端要求设置header的格式
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		// 统一处理错误，比如根据错误码提示用户
		if (error.response && error.response.status === 401) {
			// 处理token无效、过期、未提供的情况，比如跳转到登录页
			window.localStorage.clear();
			eventBus.$emit('refresh-HeaderCom');// 触发事件
			this.$router.push("/");
			
		}
		return Promise.reject(error);
	}
);

export default service;


